import SocialLogin from "@/components/SocialLogin.vue";
import HelloWorld from '@/components/HelloWorld.vue';
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "SocialLogin",
    component: SocialLogin,
  },
  {
    path: "/home/:response",
    name: "HelloWorld",
    alias: "/home/:response/**",
    component: HelloWorld,
  },
  {
    path: '/:catchAll(.*)', // Match all routes that don't match an existing route
    name: 'NotFound',
    component: SocialLogin
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
