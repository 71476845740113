
import { defineComponent } from "vue";
import { Facebook } from '../Login/FacebookLogin';
import { attachSignin } from '../Login/GoogleLogin';

declare let gapi: any;
declare let auth2: any;

export default defineComponent({
    name: "SocialLogin",
    methods:{
        loginFacebook(){
            Facebook.loginFacebook(this.$router);
        },
        // loginTwitter(){
        //     const client = new Twitter({
        //         consumer_key: "UdRDNzOPjN5EAdODN3VPB9gCe",
        //         consumer_secret: "EeXah9KmzMMo9O5dmhIYKh9XBP4ydLgq1hjNjTODoRUFehK2Na"
        //     });

        //     client
        //     .getRequestToken("https://login-demo-ts.onrender.com/")
        //     .then(res =>{
        //         console.log(res);
        //     })
        //     .catch(err =>{
        //         console.log(err);
        //     })
        // }
    },
    computed:{
        urlToGitlab(){
            const client_id = 'e1c65952edfe94e4947a5dd733e254b5e52d942877835c100b2d3e6e2744300a';
            const redirect_uri = 'https://login-demo-ts.onrender.com/home/gitlab/';
            const finalUrl = `https://gitlab.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&state=YOUR_UNIQUE_STATE_HASH`;
            return finalUrl;
        },
        urlToGithub(){
            const baseUrl = 'https://github.com/login/oauth/authorize?scope=user:email&client_id=';
            const clientID = '151f56492f38b6950186'
            return baseUrl + clientID;
        },
        urlToYahoo(){
            const baseUrl = 'https://api.login.yahoo.com/oauth2/request_auth';
            const client_id = 'dj0yJmk9Sm8yWW9TYW1pSnJHJmQ9WVdrOU1XRllNa0p0ZVdVbWNHbzlNQT09JnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PTE1';
            const redirect_uri = 'https://login-demo-ts.onrender.com/home/yahoo/';
            return `${baseUrl}?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}&nonce=8IBTHwOdqNKAWeKl7plt8g`;

        },
        urlToLinkedin(){
            const baseUrl = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=';
            const client_id= '78moyfc51uafuu';
            const redirect_uri = 'https://login-demo-ts.onrender.com/home/thelinkedin';
            return `${baseUrl}${client_id}&redirect_uri=${redirect_uri}&state=DCEeFWf45A53sdfKef424&scope=r_liteprofile`;
        }
    },
    mounted(){
        attachSignin(this.$refs["customBtn"], this.$router);
    }
    });
