declare const gapi: any;

export function attachSignin (element :any, router: any) {
  if(typeof gapi !== 'undefined'){
    gapi.load('auth2', function(){
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      const auth2 = gapi.auth2.init({
        client_id: '799099621902-9t425qbqs2gu70a08fouc95plpmpt159.apps.googleusercontent.com',
        cookiepolicy: 'https://login-demo-ts.onrender.com',
        // Request scopes in addition to 'profile' and 'email'
        //scope: 'additional_scope'
      });
  
      auth2.attachClickHandler(element, {},
      function(googleUser: any) {
        localStorage.setItem('USERNAME', googleUser.getBasicProfile().getName());
        localStorage.setItem('DATA', JSON.stringify(googleUser.getBasicProfile())); 

        router.push({name: 'HelloWorld', params:{'response': 'google'}});  

      }, function(error: any) {
        alert(JSON.stringify(error, undefined, 2));
      });
  
    });
  }else{
    setTimeout(() => {
      attachSignin(element, router);
    }, 200);
  }
}; 
