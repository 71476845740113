
import { defineComponent, onBeforeMount } from "vue";
import {Linkedin, userLinkedin} from '../Login/LinkedinLogin';

export default defineComponent({
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data(){
    return{
      token: "",
      username: "",
      data: "",
    }
  },
  mounted(){
    console.log("mounted")
    this.showTokens();
  },
  methods:{
    showTokens(){
      if(this.$route.params.response == 'gitlab'){
        const url = new URL(window.location.href.replace(/#/g,"?"));
        const token = url.searchParams.get("access_token")?.toString();
        this.token = token? token : 'No token' 
      }
      if(this.$route.params.response == 'github'){
        const url = new URL(window.location.href);
        const token = url.searchParams.get("code")?.toString();
        this.token = token? token : 'No token' 
      }
      // if(this.$route.params.response == 'yahoo'){
      //   const url = new URL(window.location.href);
      //   const token = url.searchParams.get("code")?.toString();
      //   this.token = token? token : 'No token' 
      // }
      if(this.$route.params.response == 'thelinkedin'){
        if(Linkedin()) this.asignarUsuario();
      }

      if(this.$route.params.response == 'google') this.asignarUsuario();  

    },
    volver(){
      this.$router.push({name: 'SocialLogin'});
    },
    asignarUsuario(){
      this.username = localStorage.getItem('USERNAME') || 'No username';
      this.data = localStorage.getItem('DATA') || 'No data';
    }
  }
});
