<template>
    <div id="nav">
      <router-view></router-view>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
  }, 
});
</script>

<style lang="scss">
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}

#nav {
  // border: 1px dotted black;
  width: 600px;
  margin: 80px auto 80px auto;
}
</style>


