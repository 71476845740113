import axios from 'axios';
export interface userLinkedin{
    name: string;
    data: any
}

let theAccessToken: string;



const apiClient = axios.create({
    baseURL: 'https://www.linkedin.com'
})


export function Linkedin(){
    const url = new URL(window.location.href);
    const i_token = url.searchParams.get("code")?.toString();
    const f_token = i_token? i_token : 'No token' 
    
    if(f_token == 'No token'){
        console.log('No token');
        return false;
    }else{
        console.log("token: " + f_token);
        apiClient.post('/oauth/v2/accessToken', null,{ params:{
            grant_type: 'authorization_code',
            code: f_token,
            redirect_uri: 'https://login-demo-ts.onrender.com/home/thelinkedin',
            client_id: '78moyfc51uafuu',
            client_secret: 'LF9QOMJ1wxwyyU9L'
        }, headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        }})
        .then( result => {
            theAccessToken = result.data.access_token;
            console.log('The access token: ' + theAccessToken);
            apiClient.get('/v2/me',{ headers: {"Authorization" : `Bearer ${theAccessToken}`}})
            .then(result => {
                console.log(result.data);
                localStorage.setItem('USERNAME', result.data.localizedFirstName + result.data.localizedLastName);
                localStorage.setItem('DATA', JSON.stringify(result.data));
                return true;
            })
            .catch(err =>{
                console.log('no username');
                return false;
            })
        })
        .catch( err => {
            console.log('No data')
            theAccessToken = 'No token'
            return false;
        })
    }
}


