<template>
    <div class="o-socialLoginForms">
        <h1 class="o-socialLoginForms__title">
            FractalUp Login
        </h1>
        <div class="o-socialLoginForms__options">
            <div class="m-loginForm">
                <button class="m-loginForm__loginButton -facebook"
                    @click="loginFacebook">
                    <i class="fab fa-facebook-square"></i> Login with Facebook
                </button>
            </div>
            <div class="m-loginForm">
                <button class="m-loginForm__loginButton -google" ref="customBtn"
                    > <i class="fab fa-google"></i> Login with Google
                </button>
            </div>
            <!-- <div class="m-loginForm">
                <button  class="m-loginForm__loginButton -twitter "
                    @click="loginTwitter"> <i class="fab fa-twitter"></i> Login with Twiter
                </button>
            </div>      -->
            <!-- <div class="m-loginForm">
                 <button disabled class="m-loginForm__loginButton -microsoft "
                    > <i class="fab fa-microsoft"></i> Login with Microsoft
                </button>
            </div> -->
            <!-- <div class="m-loginForm">
                <a class="m-loginForm__loginButton -yahoo"
                    :href="urlToYahoo"><i class="fab fa-yahoo"></i> Login with Yahoo
                </a>
            </div> -->
            <div class="m-loginForm">
                <a class="m-loginForm__loginButton -github"
                    :href="urlToGithub"
                    ><i class="fab fa-github"></i> Login with Github
                </a>
            </div>
            <div class="m-loginForm">
                <a class="m-loginForm__loginButton -gitlab"
                    :href="urlToGitlab"
                    ><i class="fab fa-gitlab"></i> Login with Gitlab
                </a>
            </div> 
            <div class="m-loginForm">
                <a class="m-loginForm__loginButton -linkedin"
                    :href="urlToLinkedin"
                    ><i class="fab fa-linkedin"></i> Login with Linkedin
                </a>
            </div> 
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Facebook } from '../Login/FacebookLogin';
import { attachSignin } from '../Login/GoogleLogin';

declare let gapi: any;
declare let auth2: any;

export default defineComponent({
    name: "SocialLogin",
    methods:{
        loginFacebook(){
            Facebook.loginFacebook(this.$router);
        },
        // loginTwitter(){
        //     const client = new Twitter({
        //         consumer_key: "UdRDNzOPjN5EAdODN3VPB9gCe",
        //         consumer_secret: "EeXah9KmzMMo9O5dmhIYKh9XBP4ydLgq1hjNjTODoRUFehK2Na"
        //     });

        //     client
        //     .getRequestToken("https://login-demo-ts.onrender.com/")
        //     .then(res =>{
        //         console.log(res);
        //     })
        //     .catch(err =>{
        //         console.log(err);
        //     })
        // }
    },
    computed:{
        urlToGitlab(){
            const client_id = 'e1c65952edfe94e4947a5dd733e254b5e52d942877835c100b2d3e6e2744300a';
            const redirect_uri = 'https://login-demo-ts.onrender.com/home/gitlab/';
            const finalUrl = `https://gitlab.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&state=YOUR_UNIQUE_STATE_HASH`;
            return finalUrl;
        },
        urlToGithub(){
            const baseUrl = 'https://github.com/login/oauth/authorize?scope=user:email&client_id=';
            const clientID = '151f56492f38b6950186'
            return baseUrl + clientID;
        },
        urlToYahoo(){
            const baseUrl = 'https://api.login.yahoo.com/oauth2/request_auth';
            const client_id = 'dj0yJmk9Sm8yWW9TYW1pSnJHJmQ9WVdrOU1XRllNa0p0ZVdVbWNHbzlNQT09JnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PTE1';
            const redirect_uri = 'https://login-demo-ts.onrender.com/home/yahoo/';
            return `${baseUrl}?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}&nonce=8IBTHwOdqNKAWeKl7plt8g`;

        },
        urlToLinkedin(){
            const baseUrl = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=';
            const client_id= '78moyfc51uafuu';
            const redirect_uri = 'https://login-demo-ts.onrender.com/home/thelinkedin';
            return `${baseUrl}${client_id}&redirect_uri=${redirect_uri}&state=DCEeFWf45A53sdfKef424&scope=r_liteprofile`;
        }
    },
    mounted(){
        attachSignin(this.$refs["customBtn"], this.$router);
    }
    });
</script>

<style lang="scss" scoped>

.o-socialLoginForms{
    text-align: center;
    &__title{
        text-align: center;
        padding: 5px;
    }
}

.m-loginForm{
    margin-top: 16px;
    &__loginButton{
        width: 200px;
        padding: 8px;
        font-size: 16px;
        color: white;
        background: none;
        border: none;
        font-weight: bold;
        border-radius: 13px;
        box-shadow: 2px 4px 4px rgba(0,0,0,0.5);
        cursor: pointer;

        &.-google{
            background-color: #de5246;
        }

        &.-facebook{
            background-color:  #3b5998;
        }

        &.-github{
            background-color: #0d1117;
            text-decoration: none;
            display: inline-block;
        }

        // &.-yahoo{
        //     background-color: #720e9e;
        //     text-decoration: none;
        //     display: inline-block;
        // }

        // &.-microsoft{
        //     background-color: #fff;
        //     color: black;
        //     &.-disabled{
        //         pointer-events: none;
        //         text-decoration-line: line-through;
        //     }
        // }

        // &.-twitter{
        //     background-color:#00acee;
        //     &.-disabled{
        //         pointer-events: none;
        //         text-decoration-line: line-through;
        //     }
        // }

         &.-gitlab{
            background-color:#fc6d27;
            text-decoration: none;
            display: inline-block;
        }

        &.-linkedin{
            background-color:#0e76a8 ;
            text-decoration: none;
            display: inline-block;
        }
    }
}
</style>