declare let FB: any;

function checkLoginState(router: any) { // Called when a person is finished with the Login Button.
  FB.getLoginStatus(function(response: any) {   // See the onlogin handler
    statusChangeCallback(response, router);
  });
}

function statusChangeCallback(response: any, router: any){  
  console.log('statusChangeCallback');
  console.log(response);                   
  if (response.status === 'connected') {   
    testAPI(router);  
  } else {                                 
    console.log('no logged')
  }
}

function testAPI(router: any) { // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
  console.log('Welcome!  Fetching your information.... ');
  FB.api('/me', function(response: any) {
    console.log('Successful login for: ' + response.name);
    localStorage.setItem('USERNAME', response.name);
    localStorage.setItem('DATA', JSON.stringify(response));
    router.push({name: 'HelloWorld', params:{'response': 'google'}});
  });
}


class FacebookLogin{
  logoutFacebook(router: any): void{
    FB.logout(function(response: any){
      checkLoginState(router);
    });
  }  

  loginFacebook(router: any): void{
    FB.login(function(response: any){
      checkLoginState(router);
    })
  }
}

export const Facebook = new FacebookLogin();